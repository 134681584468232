.tf-snack {
  opacity: .98;
  z-index: 200;
  -webkit-backdrop-filter: blur(8px);
  background: #e15a00;
  border: 4px solid #fcfcfc;
  border-radius: 0;
  width: 360px;
  min-height: 120px;
  padding: 0;
  font-size: 18px;
  position: fixed;
  bottom: 8px;
  right: 8px;
  box-shadow: 0 4px 30px #0000001a;
}

.tf-snack .tf-snack-label {
  color: #fafafa;
  padding: 16px;
  font-size: 22px;
  font-weight: bold;
  display: block;
}

.tf-snack .tf-snack-text {
  color: #f2f2f2;
  padding: 0 16px 16px;
  font-size: 18px;
  display: block;
}

.tf-snack .tf-snack-x {
  cursor: pointer;
  color: #f0506e;
  font-size: 24px;
  font-weight: bold;
  position: absolute;
  top: -2px;
  right: 10px;
}
/*# sourceMappingURL=index.5c1cf101.css.map */
