
.tf-snack {
    position: fixed;
    opacity: 0.98;
    bottom: 8px;
    right: 8px;
    border: 4px solid #fcfcfc;
    background: #e15a00;
    font-size: 18px;
    width: 360px;
    min-height: 120px;
    padding: 0;
    z-index: 200;
    border-radius: 0px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
}

.tf-snack .tf-snack-label {
    padding: 16px;
    display: block;
    color: #fafafa;
    font-size: 22px;
    font-weight: bold;
}

.tf-snack .tf-snack-text {
    display: block;
    padding: 0 16px 16px;
    font-size: 18px;
    color: #f2f2f2;
}

.tf-snack .tf-snack-x {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: -2px;
    font-size: 24px;
    color: #f0506e;
    font-weight: bold;
}